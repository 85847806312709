import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';


const VendorRoute = ({ children }) => {
  const { user, hasRole } = useAuth();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!user) {
      navigate('/login');
    } 
  }, [user, hasRole, navigate]);
  
  
  return children;
};

export default VendorRoute;