import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { cartApi } from '../services/api';

const CartContext = createContext(null);

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [lastUpdate, setLastUpdate] = useState(null);

  // Validation helper for cart data
  const validateCartData = useCallback((data) => {
    if (!data) {
      console.warn('Invalid cart data received');
      return null;
    }

    try {
      // Extract cart items and totals
      let items = [];
      let total_items = 0;
      let total_price = 0;
      let discount = 0;

      // Handle different response structures
      if (data.items && Array.isArray(data.items)) {
        items = data.items;
        total_items = data.total_items || items.length;
        total_price = data.total_price || 0;
        discount = data.coupon?.discount_value || 0;
      } else if (Array.isArray(data)) {
        items = data;
        total_items = data.length;
        total_price = data.reduce((sum, item) => sum + (item.subtotal || 0), 0);
      } else if (data.data) {
        return validateCartData(data.data);
      } else {
        console.warn('Unexpected cart data structure:', data);
        return null;
      }

      // Validate each cart item
      items = items.filter(item => (
        item &&
        item.asset &&
        item.asset.id &&
        item.quantity > 0 &&
        item.hours > 0
      )).map(item => ({
        id: item.id,
        asset: {
          id: item.asset.id,
          name: item.asset.name || 'Unknown Asset',
          slug: item.asset.slug || '',
          hourly_rate: parseFloat(item.asset.hourly_rate) || 0,
          description: item.asset.description || '',
          category: item.asset.category || null,
          images: item.asset.images || []
        },
        quantity: parseInt(item.quantity) || 1,
        hours: parseInt(item.hours) || 1,
        price: parseFloat(item.price) || 0,
        subtotal: parseFloat(item.subtotal) || 0,
        hourly_rate: parseFloat(item.hourly_rate) || 0
      }));

      return {
        items,
        total_items,
        total_price,
        coupon_discount: discount
      };
    } catch (err) {
      console.error('Error validating cart data:', err);
      return null;
    }
  }, []);

  // Fetch cart data
  const fetchCart = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.getCart();
      const validatedData = validateCartData(response);

      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
      } else {
        setCartItems([]);
        setTotalItems(0);
        setTotalPrice(0);
        setCouponDiscount(0);
      }
      
      return validatedData;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to fetch cart';
      console.error('Error fetching cart:', err);
      setError(errorMessage);
      setCartItems([]);
      setTotalItems(0);
      setTotalPrice(0);
      setCouponDiscount(0);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [validateCartData]);

  // Add item to cart
  const addToCart = useCallback(async (assetId, quantity = 1, hours = 1) => {
    if (!assetId) {
      toast.error('Invalid asset ID');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.addToCart({
        asset_id: parseInt(assetId),
        quantity: parseInt(quantity),
        hours: parseInt(hours)
      });

      const validatedData = validateCartData(response);
      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
        toast.success('Item added to cart successfully');
      } else {
        await fetchCart();
      }

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to add item to cart';
      console.error('Error adding to cart:', err);
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart, validateCartData]);

  // Remove item from cart
  const removeFromCart = useCallback(async (assetId) => {
    if (!assetId) {
      toast.error('Invalid asset ID');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.removeFromCart(parseInt(assetId));
      
      const validatedData = validateCartData(response);
      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
        toast.success('Item removed from cart');
      } else {
        await fetchCart();
      }

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to remove item from cart';
      console.error('Error removing from cart:', err);
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart, validateCartData]);

  // Update cart item
  const updateCartItem = useCallback(async (assetId, updates) => {
    if (!assetId) {
      toast.error('Invalid asset ID');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const requestData = {
        asset_id: parseInt(assetId)
      };

      if (updates.quantity !== undefined) {
        requestData.quantity = parseInt(updates.quantity);
      }
      if (updates.hours !== undefined) {
        requestData.hours = parseInt(updates.hours);
      }

      const response = await cartApi.updateCartItem(requestData);
      
      const validatedData = validateCartData(response);
      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
        toast.success('Cart updated successfully');
      } else {
        await fetchCart();
      }

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to update cart';
      console.error('Error updating cart:', err);
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart, validateCartData]);

  // Clear cart
  const clearCart = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.clearCart();
      
      setCartItems([]);
      setTotalItems(0);
      setTotalPrice(0);
      setCouponDiscount(0);
      setLastUpdate(new Date());
      toast.success('Cart cleared successfully');

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to clear cart';
      console.error('Error clearing cart:', err);
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  }, []);

  // Apply coupon
  const applyCoupon = useCallback(async (code) => {
    if (!code) {
      toast.error('Please enter a coupon code');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const response = await cartApi.applyCoupon(code);
      
      const validatedData = validateCartData(response);
      if (validatedData) {
        setCartItems(validatedData.items);
        setTotalItems(validatedData.total_items);
        setTotalPrice(validatedData.total_price);
        setCouponDiscount(validatedData.coupon_discount);
        setLastUpdate(new Date());
        toast.success('Coupon applied successfully');
      } else {
        await fetchCart();
      }

      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || err.message || 'Failed to apply coupon';
      console.error('Error applying coupon:', err);
      setError(errorMessage);
      toast.error(errorMessage);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [fetchCart, validateCartData]);

  // Calculate subtotal
  const calculateSubtotal = useCallback(() => {
    return cartItems.reduce((total, item) => {
      return total + (item.subtotal || 0);
    }, 0);
  }, [cartItems]);

  // Get discounted total
  const getDiscountedTotal = useCallback(() => {
    const subtotal = calculateSubtotal();
    return Math.max(0, subtotal - couponDiscount);
  }, [calculateSubtotal, couponDiscount]);

  // Check if item exists in cart
  const isInCart = useCallback((assetId) => {
    return cartItems.some(item => item.asset.id === parseInt(assetId));
  }, [cartItems]);

  // Get cart item quantity
  const getCartItemQuantity = useCallback((assetId) => {
    const item = cartItems.find(item => item.asset.id === parseInt(assetId));
    return item ? item.quantity : 0;
  }, [cartItems]);

  // Initialize cart on mount
  useEffect(() => {
    fetchCart().catch(err => {
      console.error('Error initializing cart:', err);
    });
  }, [fetchCart]);

  // Context value
  const value = {
    // Cart state
    cartItems,
    totalItems,
    totalPrice,
    loading,
    error,
    couponDiscount,
    lastUpdate,

    // Cart operations
    addToCart,
    removeFromCart,
    updateCartItem,
    clearCart,
    fetchCart,
    applyCoupon,

    // Calculations and utilities
    calculateSubtotal,
    getDiscountedTotal,
    isInCart,
    getCartItemQuantity
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};

export default CartProvider;