import api, { 
  login as apiLogin,
  loginWithGoogle as apiLoginWithGoogle,
  loginWithFacebook as apiLoginWithFacebook,
  getUserProfile as apiGetUserProfile,
  refreshToken as apiRefreshToken,
  logout as apiLogout,
  setAuthToken,
  fetchCSRFToken,
  cache
} from './api';
import { jwtDecode } from 'jwt-decode';



export const USER_ROLES = {
  ADMIN: 'ADMIN',
  VENDOR: 'VENDOR',
  USER: 'USER'
};

const AUTH_CACHE_CONFIG = {
  userProfile: {
    key: 'user_profile_cache',
    ttl: 7 * 24 * 60 * 60 * 1000 // 7 days
  },
  tokens: {
    key: 'auth_tokens_cache',
    ttl: 7 * 24 * 60 * 60 * 1000 // 7 days
  },
  csrf: {
    key: 'csrf_token_cache',
    ttl: 24 * 60 * 60 * 1000 // 24 hours
  }
};

export const AUTH_EVENTS = {
  TOKEN_REFRESH: 'TOKEN_REFRESH',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  USER_UPDATED: 'USER_UPDATED',
  AUTH_ERROR: 'AUTH_ERROR',
  SILENT_REFRESH_SUCCESS: 'SILENT_REFRESH_SUCCESS',
  SILENT_REFRESH_ERROR: 'SILENT_REFRESH_ERROR'
};

class AuthEventEmitter {
  constructor() {
    this.listeners = new Map();
  }

  on(event, callback) {
    if (!this.listeners.has(event)) {
      this.listeners.set(event, new Set());
    }
    this.listeners.get(event).add(callback);
    return () => this.off(event, callback);
  }

  off(event, callback) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).delete(callback);
    }
  }

  emit(event, data) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).forEach(callback => callback(data));
    }
  }
}


export const authEvents = new AuthEventEmitter();

class AuthService {
  constructor() {
    this.api = api;
    this.userProfileCache = null;
    this.events = new AuthEventEmitter(); // Initialize events here
    this.initializeCache();
    this.activeRequests = new Map();
    this.pendingPromises = new Map();
    this.lastProfileFetch = 0;
    this.CACHE_DURATION = 30 * 60 * 1000; // 30 minutes
    this.PROFILE_FETCH_TIMEOUT = 15000; // 15 seconds
    this.MAX_RETRY_ATTEMPTS = 3;
    this.TOKEN_REFRESH_THRESHOLD = 60 * 60 * 1000; // 1 hour before expiry
    this.isCleaningUp = false;
    this.refreshTokenPromise = null;
    this.setupTokenRefreshInterval();
    this.isRefreshing = false;
  }

  initializeCache() {
    if (!cache.has('authCache')) {
      cache.set('authCache', {
        userProfile: null,
        lastProfileFetch: null,
        pendingRequests: new Map()
      });
    }
  }

  setupTokenRefreshInterval() {
    // Check token validity every hour
    setInterval(() => {
      this.checkAndRefreshToken();
    }, 60 * 60 * 1000); // 1 hour
  }

  async checkAndRefreshToken() {
    try {
      const accessToken = this.getAccessToken();
      if (!accessToken) return;

      const decoded = jwtDecode(accessToken);
      const expiryTime = decoded.exp * 1000;
      const now = Date.now();

      // Refresh if token will expire within the threshold
      if (expiryTime - now < this.TOKEN_REFRESH_THRESHOLD) {
        const newToken = await this.silentRefresh();
        if (newToken) {
          this.events.emit(AUTH_EVENTS.SILENT_REFRESH_SUCCESS); // Use this.events
        }
      }
    } catch (error) {
    
      this.events.emit(AUTH_EVENTS.SILENT_REFRESH_ERROR, error); // Use this.events
    }
  }

  async silentRefresh() {
    if (this.isCleaningUp || this.isRefreshing) {
      return null;
    }

    if (this.refreshTokenPromise) {
      return this.refreshTokenPromise;
    }

    this.isRefreshing = true;
    this.refreshTokenPromise = this.refreshToken()
      .finally(() => {
        this.refreshTokenPromise = null;
        this.isRefreshing = false;
      });

    return this.refreshTokenPromise;
  }

  async initializeFromStorage() {
    if (this.isCleaningUp) {

      return null;
    }
  
    try {
      const accessToken = this.getAccessToken();
      const refreshToken = this.getRefreshToken();
  
      if (!accessToken || !refreshToken) {
      
        return null;
      }
  
      const isValid = this.validateToken(accessToken);
      
      if (!isValid) {
     
        try {
          await this.silentRefresh();
        } catch (error) {
         
          this.removeTokens();
          this.removeUser();
          return null;
        }
      }
      
  
      const userData = await this.getUserProfile(true);
      return userData;
    } catch (error) {
      this.removeTokens();
      this.removeUser();
      return null;
    }
  }

  

  validateToken(token) {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      // Convert to milliseconds and ensure proper comparison
      const now = Date.now();
      const exp = decoded.exp * 1000; // Convert to milliseconds
      const isValid = exp > now;
      
      
      
      return isValid;
    } catch (error) {
      
      return false;
    }
  }

  async ensureCSRFToken() {
    const cachedToken = cache.get(AUTH_CACHE_CONFIG.csrf.key);
    if (cachedToken && Date.now() - cachedToken.timestamp < AUTH_CACHE_CONFIG.csrf.ttl) {
      return cachedToken.token;
    }

    try {
      const token = await fetchCSRFToken();
      if (token) {
        cache.set(AUTH_CACHE_CONFIG.csrf.key, {
          token,
          timestamp: Date.now()
        });
        return token;
      }
      throw new Error('Invalid CSRF token response');
    } catch (error) {
      throw error;
    }
  }

  setTokens(accessToken, refreshToken) {
    if (this.isCleaningUp) {
  
      return;
    }

    try {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      sessionStorage.setItem('accessToken', accessToken);
      sessionStorage.setItem('refreshToken', refreshToken);
      
      setAuthToken(accessToken);
      
      cache.set(AUTH_CACHE_CONFIG.tokens.key, {
        accessToken,
        refreshToken,
        timestamp: Date.now()
      });

      this.events.emit(AUTH_EVENTS.TOKEN_REFRESH, { accessToken });
    } catch (error) {
      this.removeTokens();
      throw error;
    }
  }

  getAccessToken() {
    return localStorage.getItem('accessToken') || 
           sessionStorage.getItem('accessToken') || 
           cache.get(AUTH_CACHE_CONFIG.tokens.key)?.accessToken;
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken') || 
           sessionStorage.getItem('refreshToken') || 
           cache.get(AUTH_CACHE_CONFIG.tokens.key)?.refreshToken;
  }

  removeTokens() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('refreshToken');
    cache.delete(AUTH_CACHE_CONFIG.tokens.key);
    setAuthToken(null);
    this.events.emit(AUTH_EVENTS.TOKEN_EXPIRED);
  }

  async login(identifier, password) {
    if (this.isCleaningUp) {
      throw new Error('Cannot login during cleanup');
    }

    try {
      await this.ensureCSRFToken();
      const response = await apiLogin(identifier, password);

      if (!response?.access || !response?.refresh || !response?.user) {
        throw new Error('Invalid login response structure');
      }

      this.setTokens(response.access, response.refresh);
      return this.setUser(response.user);
    } catch (error) {
      this.handleAuthError(error);
      throw error;
    }
  }

  async loginWithGoogle(idToken) {
    if (this.isCleaningUp) {
      throw new Error('Cannot login during cleanup');
    }

    try {
      const response = await apiLoginWithGoogle(idToken);

      if (!response?.access || !response?.refresh || !response?.user) {
        throw new Error('Invalid Google login response');
      }

      this.setTokens(response.access, response.refresh);
      return this.setUser(response.user);
    } catch (error) {
      this.handleAuthError(error);
      throw error;
    }
  }

  async loginWithFacebook(accessToken) {
    if (this.isCleaningUp) {
      throw new Error('Cannot login during cleanup');
    }

    try {
      const response = await apiLoginWithFacebook({}, { access_token: accessToken });

      if (!response?.access || !response?.refresh || !response?.user) {
        throw new Error('Invalid Facebook login response');
      }

      this.setTokens(response.access, response.refresh);
      return this.setUser(response.user);
    } catch (error) {
      this.handleAuthError(error);
      throw error;
    }
  }

  async getUserProfile(force = false) {
    if (this.isCleaningUp) {
    
      return null;
    }

    const now = Date.now();
    
    if (!force && now - this.lastProfileFetch < this.CACHE_DURATION) {
      const cachedProfile = cache.get('userProfile');
      if (cachedProfile) {
        return cachedProfile.data;
      }
    }

    try {
      await this.ensureValidToken();
      
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), this.PROFILE_FETCH_TIMEOUT);
      
      const response = await apiGetUserProfile({ 
        force,
        signal: controller.signal,
        headers: {
          'Authorization': `Bearer ${this.getAccessToken()}`,
          'Cache-Control': 'no-cache'
        }
      });
      
      clearTimeout(timeoutId);
      
      if (response) {
        const normalizedData = this.normalizeUserData(response);
        
        if (!this.isCleaningUp) {
          cache.set('userProfile', {
            data: normalizedData,
            timestamp: now
          });
          this.lastProfileFetch = now;
        }
        return normalizedData;
      }
      
      throw new Error('Invalid profile response');
    } catch (error) {
      
      if (error.name === 'AbortError') {
        throw new Error('Profile fetch timed out');
      }
      
      if (error.response?.status === 401) {
        try {
          await this.silentRefresh();
          return this.getUserProfile(force);
        } catch (refreshError) {
          this.removeTokens();
          this.removeUser();
          throw new Error('Session expired');
        }
      }
      
      throw error;
    }
  }

  setUser(user) {
    if (!user) {
      this.removeUser();
      return null;
    }

    const normalizedUser = this.normalizeUserData(user);
    localStorage.setItem('user', JSON.stringify(normalizedUser));
    cache.set(AUTH_CACHE_CONFIG.userProfile.key, {
      ...normalizedUser,
      timestamp: Date.now()
    });
    this.events.emit(AUTH_EVENTS.USER_UPDATED, normalizedUser); // Use this.events instead of authEvents
    return normalizedUser;
  }


  getUser() {
    try {
      const cachedUser = cache.get(AUTH_CACHE_CONFIG.userProfile.key);
      if (cachedUser && Date.now() - cachedUser.timestamp < AUTH_CACHE_CONFIG.userProfile.ttl) {
        return cachedUser;
      }

      const userData = localStorage.getItem('user');
      if (userData) {
        const parsedUser = JSON.parse(userData);
        const normalizedUser = this.normalizeUserData(parsedUser);
        cache.set(AUTH_CACHE_CONFIG.userProfile.key, {
          ...normalizedUser,
          timestamp: Date.now()
        });
        return normalizedUser;
      }
    } catch (error) {
    
      this.removeUser();
    }
    return null;
  }

   removeUser() {
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
    cache.delete(AUTH_CACHE_CONFIG.userProfile.key);
    cache.delete('userProfile');
    this.events.emit(AUTH_EVENTS.USER_UPDATED, null); // Use this.events instead of authEvents
  }


  async ensureValidToken() {
    const token = this.getAccessToken();
    if (!token || !this.validateToken(token)) {
      return this.silentRefresh();
    }
    return token;
  }

  async refreshToken() {
    if (this.isCleaningUp) {
      return null;
    }

    try {
      const refreshToken = this.getRefreshToken();
      if (!refreshToken) {
        this.events.emit(AUTH_EVENTS.TOKEN_EXPIRED);
        return null;
      }

      const response = await apiRefreshToken({}, { refresh: refreshToken });
      
      if (response?.access) {
        this.setTokens(response.access, refreshToken);
        return response.access;
      }
      throw new Error('Invalid refresh token response');
    } catch (error) {
      if (!this.isCleaningUp) {
        this.handleAuthError(error);
      }
      throw error;
    }
  }


  getUserRoles(userData = null) {
    const user = userData || this.getUser();
    const roles = [];

    if (user?.is_staff) {
      roles.push(USER_ROLES.ADMIN);
    }
    if (user?.is_vendor) {
      roles.push(USER_ROLES.VENDOR);
    }
    roles.push(USER_ROLES.USER);

    return roles;
  }

  hasRole(role) {
    const userRoles = this.getUserRoles();
    return userRoles.includes(role);
  }

  isAuthenticated() {
    const token = this.getAccessToken();
    const user = this.getUser();
    return Boolean(token && this.validateToken(token) && user);
  }

  isAdmin() {
    const user = this.getUser();
    return Boolean(user?.is_staff);
  }

  isVendor() {
    const user = this.getUser();
    return Boolean(user?.is_vendor);
  }

  isVendorPending() {
    const user = this.getUser();
    return Boolean(!user?.is_vendor && user?.vendorship_requested);
  }

  normalizeUserData(userData) {
    if (!userData) {
 
      return null;
    }
  
    try {
      const normalizedData = {
        id: userData.id || '',
        first_name: userData.first_name || '',
        last_name: userData.last_name || '',
        email: userData.email || '',
        phone_number: userData.phone_number || '',
        date_of_birth: userData.date_of_birth || '',
        id_number: userData.id_number || '',
        address: userData.address || '',
        bio: userData.bio || '',
        profile_picture: userData.profile_picture || null,
        date_joined: userData.date_joined || new Date().toISOString(),
        is_vendor: Boolean(userData.is_vendor),
        is_staff: Boolean(userData.is_staff),
        vendorship_requested: Boolean(userData.vendorship_requested),
        roles: this.getUserRoles(userData),
        permissions: Array.isArray(userData.permissions) ? userData.permissions : [],
        timestamp: Date.now()
      };

      return normalizedData;
    } catch (error) {
    
      return null;
    }
  }

  normalizeError(error) {
    const normalizedError = {
      message: error.response?.data?.message || error.message || 'An error occurred',
      code: error.response?.status || 'UNKNOWN_ERROR',
      details: error.response?.data || {},
      originalError: error
    };

  
    return normalizedError;
  }

  handleAuthError(error) {
    if (this.isCleaningUp) {
      return;
    }

    if (error.response?.status === 401) {
      this.removeTokens();
      this.removeUser();
      this.events.emit(AUTH_EVENTS.TOKEN_EXPIRED);
    } else {
      this.events.emit(AUTH_EVENTS.AUTH_ERROR, error);
    }
  }


  async logout() {
    try {
      this.isCleaningUp = true;
      this.isRefreshing = false; // Reset refresh flag

      // Cancel pending requests
      this.activeRequests.forEach(controller => {
        controller.abort();
      });
      this.activeRequests.clear();
      this.pendingPromises.clear();

      // Clear all tokens and data before API call
      this.removeTokens();
      this.removeUser();
      cache.clear();

      // Attempt API logout
      try {
        await apiLogout();
      } catch (error) {
        // Ignore logout API errors
      }

      // Clear any remaining promises
      this.refreshTokenPromise = null;

    } catch (error) {
      // Ensure cleanup happens
      this.removeTokens();
      this.removeUser();
      cache.clear();
    } finally {
      this.isCleaningUp = false;
    }
  }


  getUserFullName() {
    const user = this.getUser();
    if (!user) return '';
    return `${user.first_name} ${user.last_name}`.trim() || user.email;
  }

  getFormattedJoinDate() {
    const user = this.getUser();
    if (!user?.date_joined) return '';
    try {
      return new Date(user.date_joined).toLocaleDateString();
    } catch (error) {
      
      return '';
    }
  }

  async retryOperation(operation, maxRetries = this.MAX_RETRY_ATTEMPTS) {
    if (this.isCleaningUp) {
      throw new Error('Cannot perform operation during cleanup');
    }

    let attempts = 0;
    
    while (attempts < maxRetries) {
      try {
        return await operation();
      } catch (error) {
        attempts++;
   
        
        if (attempts === maxRetries) {
          throw error;
        }
        
        // Exponential backoff
        const delay = Math.min(1000 * Math.pow(2, attempts), 8000);
        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }
  }

  cleanup() {
    this.isCleaningUp = true;
    this.activeRequests.forEach(controller => controller.abort());
    this.activeRequests.clear();
    this.pendingPromises.clear();
    this.lastProfileFetch = 0;
    this.refreshTokenPromise = null;
    this.isCleaningUp = false;
  }

  // Registration and authentication methods
  async register(userData) {
    return this.retryOperation(async () => {
      const response = await this.api.register(userData);
      return response;
    });
  }

  async registerVendor(vendorData) {
    return this.retryOperation(async () => {
      const response = await this.api.registerVendor(vendorData);
      return response;
    });
  }

  async resetPassword(email) {
    return this.retryOperation(async () => {
      const response = await this.api.resetPassword({}, { email });
      return response;
    });
  }

  async confirmResetPassword(uidb64, token, password) {
    return this.retryOperation(async () => {
      const response = await this.api.confirmResetPassword({}, { 
        uidb64, 
        token, 
        password 
      });
      return response;
    });
  }

  async resendActivation(email) {
    return this.retryOperation(async () => {
      const response = await this.api.resendActivation({}, { email });
      return response;
    });
  }

  async updateUserProfile(userData) {
    if (this.isCleaningUp) {
      throw new Error('Cannot update profile during cleanup');
    }

    try {
      const response = await this.api.updateUserProfile(userData);
      const normalizedData = this.normalizeUserData(response);
      
      if (!this.isCleaningUp) {
        this.setUser(normalizedData);
      }
      
      return normalizedData;
    } catch (error) {
    
      throw this.normalizeError(error);
    }
  }
  getEvents() {
    return this.events;
  }
}
const authService = new AuthService();
export default authService;