import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Disclosure } from '@headlessui/react';
import { 
  Bars3Icon, 
  XMarkIcon, 
  ShoppingCartIcon, 
  HeartIcon, 
  UserCircleIcon 
} from '@heroicons/react/24/outline';
import { useCart } from '../contexts/CartContext';
import { useAuth } from '../contexts/AuthContext';
import { useWishlist } from '../contexts/WishlistContext';
import logo from '../images/logos/logo_1.png';

const navigation = [
  { name: 'HOME', href: '/' },
  { name: 'AVAILABLE ITEMS', href: '/assets' },
  { name: 'LIST YOUR ITEM', href: '/list-asset' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Navbar = () => {
  const { user } = useAuth();
  const { cartItems } = useCart();
  const { wishlistItems } = useWishlist();
  const location = useLocation();
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);

  // Color scheme
  const colors = {
    primary: 'bg-gray-900 hover:bg-gray-800 text-white',
    secondary: 'bg-gray-700 hover:bg-gray-600 text-white',
    accent: 'bg-gray-300 hover:bg-gray-400 text-gray-900',
    background: 'bg-white',
    text: 'text-gray-900',
    muted: 'text-gray-500',
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleProfileClick = () => {
    if (user) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  const handleNavigation = (item) => {
    if (!user && item.href === '/list-asset') {
      navigate('/login');
    } else {
      navigate(item.href);
    }
  };

  const cartItemCount = cartItems?.length || 0;
  const wishlistItemCount = wishlistItems?.length || 0;

  return (
    <Disclosure as="nav" className={`${colors.background} shadow-md fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'py-2' : 'py-4'}`}>
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
            <div className="flex justify-between items-center">
              {/* Logo */}
              <div className="flex-shrink-0 flex items-center">
                <Link to="/">
                  <img className={`block h-8 w-auto transition-all duration-300 ${isScrolled ? 'scale-90' : ''}`} src={logo} alt="AliHire - Rent Anything" />
                </Link>
              </div>

              {/* Navigation Links - centered for large screens */}
              <div className="hidden lg:flex lg:items-center lg:justify-center flex-1">
                <div className="flex space-x-8">
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      onClick={() => handleNavigation(item)}
                      className={classNames(
                        item.href === location.pathname
                          ? `${colors.text} border-gray-900`
                          : `${colors.muted} hover:border-gray-400 hover:${colors.text}`,
                        'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium transition-colors duration-200'
                      )}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>

              {/* Icons - right aligned for large screens */}
              <div className="hidden lg:ml-4 lg:flex lg:items-center">
                <Link to="/cart" className={`flex-shrink-0 p-1 rounded-full ${colors.muted} hover:${colors.text} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 relative`}>
                  <span className="sr-only">View cart</span>
                  <ShoppingCartIcon className="h-6 w-6" aria-hidden="true" />
                  {cartItemCount > 0 && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{cartItemCount}</span>
                  )}
                </Link>

                <Link to="/wishlist" className={`flex-shrink-0 p-1 rounded-full ${colors.muted} hover:${colors.text} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ml-3 relative`}>
                  <span className="sr-only">View wishlist</span>
                  <HeartIcon className="h-6 w-6" aria-hidden="true" />
                  {wishlistItemCount > 0 && (
                    <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{wishlistItemCount}</span>
                  )}
                </Link>

                <button
                  onClick={handleProfileClick}
                  className={`flex-shrink-0 p-1 rounded-full ${colors.muted} hover:${colors.text} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 ml-3`}
                >
                  <span className="sr-only">View profile</span>
                  {user?.profile_picture ? (
                    <img className="h-8 w-8 rounded-full" src={user.profile_picture} alt="" />
                  ) : (
                    <UserCircleIcon className="h-8 w-8" aria-hidden="true" />
                  )}
                </button>
              </div>

              {/* Mobile menu button */}
              <div className="flex items-center lg:hidden">
                <Disclosure.Button className={`inline-flex items-center justify-center p-2 rounded-md ${colors.muted} hover:${colors.text} hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500`}>
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <Disclosure.Panel className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  onClick={() => handleNavigation(item)}
                  className={classNames(
                    item.href === location.pathname
                      ? `${colors.accent} border-b-2 border-gray-900`
                      : `${colors.muted} hover:${colors.text} hover:border-b-2 hover:border-gray-400`,
                    'block pl-3 pr-4 py-2 text-base font-medium'
                  )}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            
            {/* Mobile user menu */}
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-4">
                <div className="flex-shrink-0">
                  {user?.profile_picture ? (
                    <img className="h-10 w-10 rounded-full" src={user.profile_picture} alt="" />
                  ) : (
                    <UserCircleIcon className="h-10 w-10 rounded-full" aria-hidden="true" />
                  )}
                </div>
                <div className="ml-3">
                  <div className="text-base font-medium text-gray-900">{user ? user.name : 'Guest'}</div>
                  <div className="text-sm font-medium text-gray-500">{user ? user.email : ''}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as={Link}
                  to={user ? '/profile' : '/login'}
                  className={`block px-4 py-2 text-base font-medium ${colors.muted} hover:${colors.text} hover:bg-gray-100`}
                >
                  <UserCircleIcon className="mr-3 h-5 w-5 inline-block" aria-hidden="true" />
                  {user ? 'Your Profile' : 'Login'}
                </Disclosure.Button>
                <Disclosure.Button
                  as={Link}
                  to="/cart"
                  className={`block px-4 py-2 text-base font-medium ${colors.muted} hover:${colors.text} hover:bg-gray-100`}
                >
                  <ShoppingCartIcon className="mr-3 h-5 w-5 inline-block" aria-hidden="true" />
                  Cart
                  {cartItemCount > 0 && (
                    <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">{cartItemCount}</span>
                  )}
                </Disclosure.Button>
                <Disclosure.Button
                  as={Link}
                  to="/wishlist"
                  className={`block px-4 py-2 text-base font-medium ${colors.muted} hover:${colors.text} hover:bg-gray-100`}
                >
                  <HeartIcon className="mr-3 h-5 w-5 inline-block" aria-hidden="true" />
                  Wishlist
                  {wishlistItemCount > 0 && (
                    <span className="ml-2 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full">{wishlistItemCount}</span>
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
